import createjs from 'preload-js';
import { imagesToLoad } from './ImagesToLoad';
import React, { useEffect, useState } from 'react';



const Preloader = () => {


		const [pourcentage, setPourcentage] = useState(0);
		const [opacityFd, setOpacityFd] = useState(100);
		const [affichage, setAffichage] = useState("flex");


	useEffect(() => {

		const queue = new createjs.LoadQueue(false);


		const init = () => {
			queue.on("progress", handleFileProgress);
			queue.on("complete", handleComplete);
			queue.on("error", loadError);
			queue.loadManifest(imagesToLoad);
		}

		const handleFileProgress = (event) => {
			const nombre = Math.floor(queue.progress * 100);
			setPourcentage(nombre);
		}


		const loadError = (evt) => {
		    console.log("Error!",evt.item.id);
		}


		const handleComplete = (event) => {
			setOpacityFd(0);
			// setAffichage("none");
			// setIsLoaded(false);

			const timer = setTimeout(() => {
				setAffichage("none");
			}, 900);
			return () => clearTimeout(timer);
		}


			init();


	}, []);




  return (
  	<div>
	  	<div className="container-preloader" style={{opacity: `${opacityFd}`, display: `${affichage}`}}>
		  	<div className="pourcentage-preloader" style={{opacity: `${opacityFd}`}}>
		  		{`${pourcentage}%`}
		    </div>
		    <div className="progressBar-preloader" style={{width: `${pourcentage}%`, opacity: `${opacityFd}`}}></div>
	    </div>
    </div>
  );
}

export default Preloader;