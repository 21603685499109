import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items : {},
  ready: false
}

const dataBaseSlice = createSlice({
	name: 'items',
	initialState,
	reducers: {

		addItems: (state, action) => {
			state.items = action.payload
			state.ready = true
		}
	}
})

export const { addItems } = dataBaseSlice.actions
export const getAllItems = (state) => state.database

export default dataBaseSlice.reducer