
export const menuData = [
	{
		title: 'accueil',
		id : "Menu 1",
		path: '/'
	},
	{
		title: 'pourquoi ?',
		id : "Menu 2",
		path: '/pourquoi'
	},
	{
		title: 'principes',
		id : "Menu 3",
		path: '/principes'
	},
	{
		title: 'troubles',
		id : "Menu 4",
		path: '/troubles'
	},
	{
		title: 'contact',
		id : "Menu 5",
		path: '/contact'
	}
]

