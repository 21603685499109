

const EasyMap = () => {

  return  (
      <div className="map">
        <iframe className="taille-map"
          // width="600"
          // height="450"
          style={{border:0}}
          // loading="lazy"
          // loading="auto"
          loading="eager"
          title="map"
          allowFullScreen
           // src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBxyGXeFxBpYp6OKNF4ssXyoV68hxtud6s
           //  &q=457+Av.+de+Chabeuil,+26000+Valence"
           src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBxyGXeFxBpYp6OKNF4ssXyoV68hxtud6s
            &q=457+Rte+de+Chabeuil,+26000+Valence"
        >
        </iframe>
      </div>
  ) 
}

export default (EasyMap)