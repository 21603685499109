
export const imagesToLoad = [
	{
		id: "bandeau",
		src: "/assets/top9.jpg"
	},
	{
		id: "homme header accueil",
		src: "/assets/homme_accueil.png"
	},
	{
		id: "visuel-1 accueil",
		src: "/assets/visuel_accueil.jpg"
	},
	{
		id: "femme header pourquoi",
		src: "/assets/femme_pourquoi.png"
	},
	{
		id: "visuel-1 pourquoi",
		src: "/assets/visuel_pourquoi.jpg"
	},
	{
		id: "visuel-2 pourquoi",
		src: "/assets/visuel_pourquoi2.jpg"
	},
	{
		id: "enfant header principes",
		src: "/assets/enfant.png"
	},
	{
		id: "visuel-1 principes",
		src: "/assets/visuel_principes.jpg"
	},
	{
		id: "schema principes",
		src: "/assets/schema_principes.jpg"
	},
	{
		id: "visuel-2 principes",
		src: "/assets/pablo.jpg"
	},
	{
		id: "homme header troubles",
		src: "/assets/homme_troubles.png"
	},
	{
		id: "visuel-1 troubles",
		src: "/assets/visuel_troubles_bis.jpg"
	},
	{
		id: "visuel-2 troubles",
		src: "/assets/visuel_troubles2.jpg"
	},
	{
		id: "femme header contact",
		src: "/assets/old_lady.png"
	},
	{
		id: "visuel-1 contact",
		src: "/assets/avatar.png"
	},
	{
		id: "visuel-2 contact",
		src: "/assets/cabinet.jpg"
	},
	{
		id: "homme header mentions",
		src: "/assets/homme_mentions.png"
	}
]

