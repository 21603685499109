import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import api from './constants/constants.js'
import { addItems, getAllItems } from './constants/dataBaseSlice.js'

import Navbar from './components/navigation/Navbar';
import Accueil from './components/Accueil';
import Pourquoi from './components/Pourquoi';
import Principes from './components/Principes';
import Troubles from './components/Troubles';
import Contact from './components/Contact';
import Mentions from './components/Mentions';
import Footer from './components/Footer';
import { Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/navigation/ScrollToTop';
import { AnimatePresence } from 'framer-motion';
import Preloader from './components/Preloader';


const App = () => {

	const dispatch = useDispatch()

	const getItems = useSelector(getAllItems)


	useEffect(() => {
			const fetchItems = async () => {
			const response = await api.get('/items')
			.catch((err) => {
				console.log(err)
			})
				dispatch(addItems(response.data))
			}
			fetchItems()

	}, [])


	// useEffect(() => {
	// 		console.log(getItems)

	// }, [getItems])


	if(getItems.ready === true) {

		//console.log(getItems)

		  return (
		  	<div>
				<Preloader />
			    <div className="container-global">
				    <ScrollToTop>
				    	<Navbar />
				    	<AnimatePresence exitBeforeEnter>
				    	<Switch>
							<Route exact path="/" component={Accueil} />
							<Route exact path="/pourquoi" component={Pourquoi} />
							<Route exact path="/principes" component={Principes} />
							<Route exact path="/troubles" component={Troubles} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/mentions" component={Mentions} />
						</Switch>
						</AnimatePresence>
						<Footer />
					</ScrollToTop>
			    </div>
			</div>
		  )

	} else {
		return null
	}
}

export default App;