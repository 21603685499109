import {gsap} from "gsap";
import React, { useState } from 'react';
import Menu from './Menu';
import { NavLink } from 'react-router-dom';
import { menuData } from './MenuData';

const Navbar = () => {

	const [menu, setMenu] = useState(false);

	const openMenu = () => {

		gsap.to("div.trait.bar1",0.8,{rotate: -45 + "deg", y: 9, backgroundColor:"#FFFFFF", ease: 'power4.inOut'});
		gsap.to("div.trait.bar2",0.8,{opacity: 0, ease: 'power4.inOut'});
		gsap.to("div.trait.bar3",0.8,{rotate: 45 + "deg", y: -9, backgroundColor:"#FFFFFF", ease: 'power4.inOut'});
		gsap.to("div.title-menu",0.8,{opacity: 0, ease: 'power4.inOut'});
		gsap.to("div.page-menu",0.8,{y:100 + "vh", rotation: 0.01, skewX:0.01, skewY:0.01, ease: 'power4.inOut'});
		setMenu(true);

	};


	const closeMenu = () => {

		gsap.to("div.trait.bar1",1,{rotate: 0 + "deg", y: 0, backgroundColor:"#000000", ease: 'power4.inOut'});
		gsap.to("div.trait.bar2",1,{opacity: 1, ease: 'power4.inOut'});
		gsap.to("div.trait.bar3",1,{rotate: 0 + "deg", y: 0, backgroundColor:"#000000", ease: 'power4.inOut'});
		gsap.to("div.title-menu",1.2,{opacity: 1, ease: 'power4.inOut'});
		gsap.to("div.page-menu",1,{y:-100 + "vh", rotation: 0.01, skewX:0.01, skewY:0.01, ease: 'power4.inOut'});
		setMenu(false);

	};

	const toggleMenu = () => {
		menu ? closeMenu() : openMenu();
	}

	return (
		<div >

			<header className="navbar-wrapper">
			    <nav className="site-nav">
			        <ul>
				        {menuData.map((item, id) => (
							<NavLink exact activeClassName="current" to={item.path} key={item.id}>
								<li>
									{item.title}
								</li>
							</NavLink>	
						))}
			        </ul>
				    <div className="facebook">
			        	<a href="https://www.facebook.com/Psychologue-Roum%C3%A9as-686906878084751/?ref=ts&fref=ts" target="_blank" rel="noopener noreferrer"><img src="/assets/facebook_b_30px.png" alt="" /></a>
			        </div>
			    </nav>
		    </header>
		    <div>
				<Menu closeMenu={closeMenu} />
			</div>
	  		<div>
				<div className="menu" onClick={toggleMenu}>
					<div>
						<div className="trait bar1"></div>
						<div className="trait bar2"></div>
						<div className="trait bar3"></div>
					</div>
					<div className="title-menu">MENU</div>
				</div>
			</div>

		</div>
	);
}


export default Navbar;

	/*<div className="title-menu">MENU</div>*/

	/*					<div className="facebook-hamburger">
			        	<a href="https://www.facebook.com/Psychologue-Roum%C3%A9as-686906878084751/?ref=ts&fref=ts" target="_blank" rel="noopener noreferrer"><img src="/assets/facebook_b_30px.png" alt="" /></a>
			        </div>*/