import axios from "axios"

export default axios.create({

	///////Changer l'adresse lors du run build ->ref adresse api site
	/////penser a changer CORS middleware dans lumen pour la securité
	baseURL: "https://psychologue-valence.fr/api_eloise_roumeas/public/index.php/api",
	headers: {
		"Content-type": "application/json"
	}
})