import { motion } from 'framer-motion';
import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'

import { getAllItems } from '../constants/dataBaseSlice.js'

import { EditorState, convertFromRaw, convertToRaw }  from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"



const containerVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.4
		}
	},
		exit: {
		opacity:0,
		transition: {
			duration: 0.4
		}
	}
}


const Accueil = () => {

	const getItems = useSelector(getAllItems)

	const [editorState, setEditorState] = useState(EditorState.createEmpty())


	useEffect(() => {
			//console.log(getItems.items[0])
			loadContent()

	}, [getItems.items[0]])





	const loadContent = () => {

		// Getting content from database
		let dataBaseContent = getItems.items[0].text
		
		// Getting content back
		let content = convertFromRaw(JSON.parse(dataBaseContent))
			
		// Setting the state
		setEditorState(EditorState.createWithContent(content))
	}

	

	const inlineStyles = [
		{ label: "B", style: "BOLD" }
	]


	const styleMap = {
	  BOLD: {
	    fontWeight:'normal',
	    fontFamily: 'Roboto Bold'
	  }
	}




	return (
		<motion.div className="wrapper-accueil"
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			exit="exit"
		>

		  <section className="header-accueil">
			  <div className="bandeau">
				  	<img src="/assets/top9.jpg" alt="bandeau" />
			  </div>
		  	<div className="grid-header-accueil">
		        <div className="presentation">
		          <p className="nom">
		            Eloïse Rouméas
		          </p>
		          <p className="poste">
		            PSYCHOLOGUE Clinicienne
		          </p>
		          <p className="therapie">
		            Spécialisée en Thérapie Cognitive<br /> 
		            et Comportementale
		          </p>
		          <p className="people">
		            Enfant, Adolescent et Adulte
		          </p>
		        </div>
		        <div className="homme-accueil">
		        	<img src="/assets/homme_accueil.png" alt="homme accueil header" />
		        </div>
		    </div>
	      </section>

	      <section className="accueil-section2 grid">
	        <div className="accueil-img-contenu">
	          <img src="/assets/visuel_accueil.jpg" alt="" />
	          <div className="fd-jaune"></div>
	        </div>
	        <div className="text-accueil">
	          <Editor
	          		toolbarHidden
					  editorState={editorState}
					  wrapperClassName="wrapperClassName"
					  editorClassName="the-editor"
					  customStyleMap={styleMap}
					  onEditorStateChange={(editorState) => setEditorState(editorState)}
					  readOnly = "true"
					  toolbar={{
					  	options: ['link'],

						link: {
						    inDropdown: false,
						    className: undefined,
						    component: undefined,
						    popupClassName: undefined,
						    dropdownClassName: undefined,
						    showOpenOptionOnHover: false,
						    defaultTargetOption: '_self',
						    options: ['link', 'unlink'],
						  }

					  }}
					/>
	        </div>
	      </section>

		</motion.div>
	);
}


export default Accueil;