import { motion } from 'framer-motion';


const containerVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.4
		}
	},
		exit: {
		opacity:0,
		transition: {
			duration: 0.4
		}
	}
}

const Mentions = () => {
	return (
		<motion.div className="wrapper-accueil"
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			exit="exit"
		>

			  <section className="header-mentions">
				<div className="bandeau">
				  	<img src="/assets/top9.jpg" alt="bandeau" />
			  	</div>
			  	<div className="grid-header-accueil">
			        <div className="presentation-mentions">
			          <p className="nom">
			            Eloïse Rouméas
			          </p>
			          <p className="poste">
			            PSYCHOLOGUE Clinicienne
			          </p>
			          <p className="therapie">
			            Spécialisée en Thérapie Cognitive<br /> 
			            et Comportementale
			          </p>
			          <p className="people">
			            Enfant, Adolescent et Adulte
			          </p>
			        </div>
			        <div className="homme-mentions">
			        	<img src="/assets/homme_mentions.png" alt="homme header mentions" />
			        </div>
			    </div>
		      </section>

		      <section className="mentions-section2 grid-mentions">
		        <div className="text-mentions-gauche">
			        <p>
			          	<strong>INFORMATIONS GÉNÉRALES&nbsp;:</strong><br />
			            Roumeas Eloïse<br />
						457 Route de chabeuil<br />
						26000 VALENCE<br />
						06 07 35 49 14<br />
						Site Web&nbsp;: <a href="http://www.psychologue-valence.fr/" target="_blank" rel="noopener noreferrer">www.psychologue-valence.fr</a><br />
						Email&nbsp;: <a href="mailto:eloiseroumeas@outlook.fr" rel="noopener noreferrer">eloiseroumeas@outlook.fr</a><br />
						Identifiant SIREN&nbsp;: 802515759<br />
						Identifiant SIRET&nbsp;: 80251575900024<br />
						CODE APE&nbsp;: 8690F<br /><br />

						<strong>HÉBERGEMENT&nbsp;:</strong><br />
						IONOS<br />
						7 pl. de la gare<br />
						57200 SARREGUEMINES<br />
						Web&nbsp;: <a href="https://www.ionos.fr/" target="_blank" rel="noopener noreferrer">www.ionos.fr</a>
					</p>
		        </div>
		        <div className="text-mentions-droite">
			        <p>
						<strong>DROITS DE LA PROPRIÉTÉ INTELLECTUELLE&nbsp;:</strong><br />
						L’ensemble du contenu de ce site est régi par le code de la Propriété Intellectuelle.<br />
						La présentation et le contenu du présent site constituent une oeuvre protégée par les lois en vigueur sur la propriété intellectuelle, 
						dont Roumeas Eloïse est titulaire.<br />
						Les photographies, textes, slogans, dessins, modèles, images, séquences animées sonores ou non ainsi que toutes œuvres intégrés 
						dans le site sont la propriété de Roumeas Eloïse ou de tiers l’ayant autorisé à les utiliser.<br />
						Les reproductions, sur un support papier ou informatique, dudit site et des éléments qui y sont reproduits sont autorisées sous 
						réserve qu'elles soient strictement réservées à un usage personnel excluant tout usage à des fins publicitaires et/ou commerciales 
						et/ou d'information et/ou qu'elles soient conformes aux dispositions de l'article L. 122-5 du Code de la Propriété Intellectuelle.<br />
						A l'exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification, par quelque procédé 
						que ce soit et sur quelque support que ce soit, de tout ou partie du site, de tout ou partie des différentes œuvres et modèles 
						de produits qui le composent, sans avoir obtenu l'autorisation préalable de Roumeas Eloïse, est strictement interdite et 
						constitue un délit de contrefaçon puni de deux ans d'emprisonnement et de 150 000 euros d'amende.
						<br /><br />
						<strong>DONNÉES PERSONNELLES ET AUTRES DONNÉES&nbsp;:</strong><br />
						Conformément à l'article 34 de la Loi "Informatique et Libertés" n°78-17 du 6 janvier 1978, vous disposez d'un droit d'accès 
						et de rectification des données vous concernant en nous contactant.<br /><br />

						<strong>LIENS HYPERTEXTES&nbsp;:</strong><br />
						La mise en place d'un lien hypertexte vers le site www.psychologue-valence.fr nécessite une autorisation préalable écrite 
						de Roumeas Eloïse. Si vous souhaitez mettre en place un lien hypertexte vers notre site, vous devez en conséquence prendre contact avec elle.
						<br /><br />
						Roumeas Eloïse ne peut en aucun cas être tenue pour responsable des sites ayant un lien hypertexte avec le présent site 
						et décline toute responsabilité quant à leur contenu et à leur utilisation.
						<br /><br />
						<strong>DROIT APPLICABLE&nbsp;:</strong><br />
						Les présentes Conditions Générales sont soumises au droit interne français. La langue des Conditions Générales est la langue française. 
						En cas de litige, les tribunaux français seront seuls compétents.
					</p>
		        </div>
		      </section>

		</motion.div>
	);
}


export default Mentions;