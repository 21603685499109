import { NavLink } from 'react-router-dom';
import { menuData } from './MenuData';

const Menu = ({closeMenu}) => {

	return (
		<div className="page-menu">		
			<div className="wrapper-menu">
				<div className="agencement-menu">
					{menuData.map((item, id) => (
						<NavLink exact activeClassName="current" to={item.path} key={item.id}>
							<div className="menu-individuel" onClick={closeMenu}>
								{item.title}
							</div>
						</NavLink>	
					))}
				</div>
			</div>
		</div>
	)

}

export default Menu;