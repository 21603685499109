
import { NavLink } from 'react-router-dom';


const Footer = () => {
	return (
		<div className="wrapper-footer">

		  <footer className="grid-footer">
		  	<div className="bloc1-footer">
		        <p className="eloise">
		          Eloïse Rouméas
		        </p>
		        <p className="adresse">
		          457 Route de Chabeuil<br />
		          26000 VALENCE 
		        </p>
	        </div>
	        <div className="bloc2-footer">
		        <p className="telephone">
		          <a href="tel:+33607354914">06 07 35 49 14</a> 
		        </p>
		        <p className="adresse-mail">
		          <a href="mailto:eloiseroumeas@outlook.fr" rel="noopener noreferrer">eloiseroumeas@outlook.fr</a>
		        </p>
	        </div>
	        <div className="bloc3-footer">
		        <p className="mentions">
		          <NavLink exact activeClassName="current-footer" to="/mentions" >Mentions Légales</NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		        </p>
		        <ul>
		          <li className="facebook-footer">
		            <a href="https://www.facebook.com/Psychologue-Roum%C3%A9as-686906878084751/?ref=ts&fref=ts" target="_blank" rel="noopener noreferrer"><img src="/assets/facebook.png" alt="" /></a>
		          </li>
		        </ul>
	        </div>
	      </footer>

		</div>
	);
}


export default Footer;