import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import App from './App';
import './css/App.css';
import './css/Pourquoi.css';
import './css/Troubles.css';
import './css/Principes.css';
import './css/Naturo.css';
import './css/Contact.css';
import './css/Preloader.css';
import './css/Mentions.css';
import { BrowserRouter } from 'react-router-dom';



import rootReducer from './reducer/rootReducer.js';

const store = configureStore({
	reducer: rootReducer
})

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
  	<BrowserRouter>
   		<App />
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
