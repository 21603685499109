import EasyMap from './EasyMap';
import { motion } from 'framer-motion';


const containerVariants = {
	hidden: {
		opacity: 0
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.4
		}
	},
		exit: {
		opacity:0,
		transition: {
			duration: 0.4
		}
	}
}


const Contact = () => {


	return (
		<motion.div className="wrapper-accueil"
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			exit="exit"
		>

			  <section className="header-contact">
				<div className="bandeau">
				  	<img src="/assets/top9.jpg" alt="bandeau" />
			    </div>
			  	<div className="grid-header-accueil">
			        <div className="presentation-contact">
			          <p className="nom">
			            Eloïse Rouméas
			          </p>
			          <p className="poste">
			            PSYCHOLOGUE Clinicienne
			          </p>
			          <p className="therapie">
			            Spécialisée en Thérapie Cognitive<br /> 
			            et Comportementale
			          </p>
			          <p className="people">
			            Enfant, Adolescent et Adulte
			          </p>
			        </div>
			        <div className="old-contact">
			        	<img src="/assets/old_lady.png" alt="femme contact header" />
			        </div>
			    </div>
		      </section>

		  	<section className="contact-section2 grid-contact">
		        <div className="contact-img-contenu">
		          <img src="/assets/avatar.png" alt="" />
		          <p className="texte-contact"><span className="titre-contact">Rouméas Eloïse</span><br /> 
					457 Route de chabeuil<br /> 
					26000 VALENCE<br /> 
					<a href="tel:+33607354914" rel="noopener noreferrer">06 07 35 49 14</a> 
					<br /><br />
					<a href="mailto:eloiseroumeas@outlook.fr" rel="noopener noreferrer">eloiseroumeas@outlook.fr</a>
				   </p>
		        </div>
		        <div className="carte-contact">
		        	<EasyMap />
		        </div>
		        <div className="cabinet-contact">
		      		<img src="/assets/cabinet.jpg" alt="" />
		      		<div className="fd-vert-contact"></div>
		     	</div>
		    </section>
		

		</motion.div>
	);
}


export default Contact;